import { Button, ButtonGroup, FormControl } from "@mui/material";
import React from "react";

const MaterialConjs = ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  showNot,
  notLabel,
}) => {
  // TIP: disabled=true if only 1 rule; readonly=true if immutable mode
  const conjsCount = Object.keys(conjunctionOptions).length;
  const lessThenTwo = disabled;

  const onClick = (value) => setConjunction(value);
  const onNotClick = (checked) => setNot(checked);

  const renderOptions = () =>
    Object.keys(conjunctionOptions).map((key) => {
      const { id, label, checked } = conjunctionOptions[key];
      const postfix = setConjunction.isDummyFn ? "__dummy" : "";
      if (readonly && !checked) return null;
      return (
        <Button
          key={id + postfix}
          id={id + postfix}
          color={checked ? "primary" : "inherit"}
          value={key}
          onClick={() => onClick(key)}
          disabled={readonly}
        >
          {label}
        </Button>
      );
    });

  const renderNot = () => {
    if (readonly && !not) return null;
    return (
      <Button
        key={id}
        id={`${id}__not`}
        color={not ? "secondary" : "inherit"}
        onClick={() => onNotClick(!not)}
        disabled={readonly}
      >
        {notLabel || "NOT"}
      </Button>
    );
  };

  return (
    <FormControl>
      <ButtonGroup
        disableElevation
        variant="contained"
        size="small"
        disabled={readonly}
      >
        {showNot && renderNot()}
        {conjsCount > 1 && !lessThenTwo && renderOptions()}
      </ButtonGroup>
    </FormControl>
  );
};

export default MaterialConjs;
