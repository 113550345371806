import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
// core components
import MaterialButton from "./core/MaterialButton";
import MaterialButtonGroup from "./core/MaterialButtonGroup";
import materialConfirm from "./core/MaterialConfirm";
import MaterialConjs from "./core/MaterialConjs";
// field select widgets
import MaterialFieldSelect from "./core/MaterialFieldSelect";
import MaterialValueSources from "./core/MaterialValueSources";
import MaterialAutocompleteWidget from "./value/MaterialAutocomplete";
import MaterialBooleanWidget from "./value/MaterialBoolean";
import MaterialDateWidget from "./value/MaterialDate";
import MaterialDateTimeWidget from "./value/MaterialDateTime";
import MaterialMultiSelectWidget from "./value/MaterialMultiSelect";
import MaterialNumberWidget from "./value/MaterialNumber";
import MaterialNumberWithDollarWidget from "./value/MaterialNumberWithDollar";
import MaterialRangeWidget from "./value/MaterialRange";
import MaterialSelectWidget from "./value/MaterialSelect";
import MaterialSliderWidget from "./value/MaterialSlider";
// value widgets
import MaterialTextWidget from "./value/MaterialText";
import MaterialTextAreaWidget from "./value/MaterialTextArea";
import MaterialTimeWidget from "./value/MaterialTime";

// provider
const MaterialProvider = ({ config, children }) => {
  const settingsTheme = config.settings.theme || {};
  const settingsLocale = config.settings.locale || {};
  const themeConfig = settingsTheme.material;
  const locale = settingsLocale.material;
  const useTheme = themeConfig || locale;
  const theme = useTheme ? createTheme(themeConfig, locale) : null;

  const base = <div className="mui">{children}</div>;

  const withTheme = theme ? (
    <ThemeProvider theme={theme}>{base}</ThemeProvider>
  ) : (
    base
  );
  return withTheme;
};

export default {
  MaterialTextWidget,
  MaterialTextAreaWidget,
  MaterialDateWidget,
  MaterialDateTimeWidget,
  MaterialTimeWidget,
  MaterialSelectWidget,
  MaterialNumberWidget,
  MaterialNumberWithDollarWidget,
  MaterialSliderWidget,
  MaterialRangeWidget,
  MaterialBooleanWidget,
  MaterialMultiSelectWidget,
  MaterialAutocompleteWidget,
  MaterialFieldSelect,
  MaterialButton,
  MaterialButtonGroup,
  MaterialConjs,
  MaterialValueSources,
  materialConfirm,
  MaterialProvider,
};
