import axiosClient from "./axiosClient";

const createEmail = (param) => {
  const url = "/email";
  return axiosClient.post(url, param);
};

const saveTemplate = (param) => {
  const url = "/email/template";
  return axiosClient.post(url, param);
};

const getTemplateList = () => {
  const url = `/email/template`;
  return axiosClient.get(url);
};

export { createEmail, saveTemplate, getTemplateList };
