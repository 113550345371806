import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Divider,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { getAppUrl } from "../../api-services/axiosClient";
import HeaderComponent from "../../components/HeaderComponent";
import { CustomOutlineButton } from "../../components/custom-button";
import ContactContext from "../../context/contact-context";
import ContactAlert from "../../features/contact/contact-alert.tsx";
import ContactTable from "../../features/contact/contact-table";
import ShareDetailsDialog from "../../features/contact/share-details-dialog";
import TotalContact from "../../features/contact/total-contact";
import {
  useAlertList,
  useContactList,
  useCreateAlert,
  useGetContactListByAlert,
  useGetSatisfiedContactsByAlertsWithinGivenContacts,
  useUpdateAlert,
  useImportContactlist,
} from "../../hooks/contact.hook";
import { monthDayFormater } from "../../utils/calendar-util";
import {
  formatNumberWithDollar,
  formatNumberWithPercent,
} from "../../utils/numberFormater";
import { currentAuthenticatedUser } from "../../utils/user-auth-provider";
import EmailEditorDialog from "../../features/contact/email-editor-dialog";
import ContactAlertDialog from "../../features/contact/contact-alert-dialog.tsx";
import { useIsCompanyAdmin } from "../../hooks/user.hooks";
import { useCreateEmail } from "../../hooks/email.hooks";
import ImportContactButton from "../../features/contact/import-contact-btn";
import FilePreviewDialog from "../../features/contact/file-preview-dialog";

const formatContactNumber = (value) => {
  return typeof value === "number" || value === 0
    ? formatNumberWithDollar(value)
    : "Not Available";
};

const formatContactRatio = (value) => {
  return typeof value === "number" || value === 0
    ? formatNumberWithPercent(value)
    : "Not Available";
};

const formatBoolean = (value) => {
  return value ? "Yes" : "No";
};

const columns = [
  { id: "checkbox", label: "", minWidth: 50 },
  { id: "contactName", label: "Contact Name", minWidth: 170 },
  { id: "contactDetail", label: "Contact Details", minWidth: 100 },
  {
    id: "maxBuyingPower",
    label: "Max Real Buying Power",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "minBuyingPower",
    label: "Min Real Buying Power",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "cashAvailable",
    label: "Cash Available",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "debtOutstanding",
    label: "Debt Outstanding",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "lastLoginTime",
    label: "Last App Vist",
    minWidth: 100,
  },
  {
    id: "lvr",
    label: "LVR",
    format: formatContactRatio,
    minWidth: 100,
  },
  {
    id: "maxLoanAmount",
    label: "Max Loan Amount",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "currentInterestRate",
    label: "Current Interest Rate",
    format: formatContactRatio,
    minWidth: 100,
  },
  {
    id: "hasMetBuyingGoal",
    label: "Met Buying Goal",
    minWidth: 100,
    format: formatBoolean,
  },
  {
    id: "hasMetSavingGoal",
    label: "Met Saving Goal",
    minWidth: 100,
    format: formatBoolean,
  },
  {
    id: "excessMonthlySurplus",
    label: "Excess Monthly Surplus",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "equity",
    label: "Equity",
    format: formatContactNumber,
    minWidth: 100,
  },
  {
    id: "alerts",
    label: "Alerts",
    minWidth: 100,
  },
  {
    id: "actionButton",
    label: "",
    minWidth: 100,
  },
];

const createData = ({
  id,
  contactName,
  contactDetail,
  maxBuyingPower,
  minBuyingPower,
  cashAvailable,
  debtOutstanding,
  lastAppVisit,
  lvr,
  maxLoanAmount,
  currentInterestRate,
  hasMetBuyingGoal,
  hasMetSavingGoal,
  excessMonthlySurplus,
  equity,
  handleViewAccount,
  handleShareDetail,
  handleCheckAccount,
}) => {
  return {
    id,
    contactName,
    contactDetail,
    maxBuyingPower,
    minBuyingPower,
    cashAvailable,
    debtOutstanding,
    lastContact: lastAppVisit || "Not Available",
    lastAppVisit: lastAppVisit || "Not Available",
    checkbox: <Checkbox onClick={handleCheckAccount} />,
    lastLoginTime: lastAppVisit || "Not Available",
    lvr,
    maxLoanAmount,
    currentInterestRate,
    hasMetBuyingGoal,
    hasMetSavingGoal,
    excessMonthlySurplus,
    equity,
    actionButton: (
      <div className="contact-table__action-button-container">
        <CustomOutlineButton
          label="View Account"
          onClick={handleViewAccount}
          buttonType="secondary"
        />
        <CustomOutlineButton
          label="Share Details"
          onClick={handleShareDetail}
          buttonType="secondary"
        />
      </div>
    ),
  };
};

const ContactPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: isCompanyAdmin } = useIsCompanyAdmin();
  const queryClient = useQueryClient();
  const [checkedEmails, setCheckedEmails] = useState([]);

  const [contactPage, setContactPage] = useState(1);
  const [contactLimit, setContactLimit] = useState(10);
  const useContactListQuery = useContactList(contactLimit, contactPage);
  const {
    data: { data: contactList = [], total: totalGeneralContacts = 0 } = {},
    refetch,
  } = useContactListQuery;
  const onContactTableChange = (limit, page) => {
    setContactLimit(limit);
    setContactPage(page);
  };

  // --------- share detail logic section ------------
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenEmailEditorDialog, setIsOpenEmailEditorDialog] = useState(false);
  const [isOpenShareDetailDialog, setIsOpenShareDetailDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");
  const [period, setPeriod] = useState(0);
  const [createContactDTOs, setCreateContactDTOs] = useState([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const createEmail = useCreateEmail();
  const menuItems = [
    {
      value: -1,
      label: "Unsubscribe",
    },
    {
      value: 0,
      label: "Send once off",
    },
    {
      value: 7 * 4,
      label: `Every 4 weeks`,
    },
    {
      value: 7 * 6,
      label: `Every 6 weeks`,
    },
    {
      value: 7 * 8,
      label: `Every 8 weeks`,
    },
    {
      value: 7 * 12,
      label: `Every 12 weeks`,
    },
  ];

  const handleViewAccount = async (userId) => {
    const authUser = await currentAuthenticatedUser();
    const authUserName = authUser.username;
    window.open(
      `${getAppUrl()}/?viewAsUserId=${userId}&authUserName=${authUserName}`
    );
  };

  const handleDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  const handleShareDetailDialog = useCallback(
    (id) => {
      setSelectedContact(id);
      setIsOpenShareDetailDialog(!isOpenShareDetailDialog);
    },
    [isOpenShareDetailDialog]
  );

  const handleEmailEditorDialog = (event) => {
    setPeriod(event.target.value);
    if (event.target.value === -1) {
      createEmail.mutate({
        emailList: checkedEmails,
        templateId: null,
        period: -1,
      });
    } else {
      setIsOpenEmailEditorDialog(!isOpenEmailEditorDialog);
    }
  };

  const handleCheckAccount = useCallback((email, event) => {
    setCheckedEmails((prevCheckedEmail) => {
      if (event.target.checked) {
        return [...prevCheckedEmail, email];
      }
      return prevCheckedEmail.filter((checkedEmail) => checkedEmail !== email);
    });
  }, []);
  // --------- share detail logic section ------------

  // --------- alert logic section ------------
  // alertData is for storing data on edit or create
  const [alertData, setAlertData] = useState(null);
  // selectedContactAlert is for storing selected alert id
  const [selectedContactAlert, setSelectedContactAlert] = useState(null);
  // is selecting the total alert item (first item)
  const [isAlertTotalItemSelected, setIsAlertTotalItemSelected] = useState(
    true
  );
  const {
    data: { data: contactListByAlert = [], total: totalContactsByAlert } = {},
    isSuccess: isFetchContactByAlertSuccess,
  } = useGetContactListByAlert(selectedContactAlert, contactLimit, contactPage);

  // create/edit modal
  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);

  const handleSelectContactAlert = (isTotalItem, alert) => {
    setContactPage(1);
    setIsAlertTotalItemSelected(isTotalItem);
    setSelectedContactAlert(alert?.id);
    setAlertData(alert);
    if (selectedContactAlert) {
      queryClient.invalidateQueries(["contactList", contactLimit, contactPage]);
    } else {
      queryClient.invalidateQueries([
        "contactListByAlertId",
        selectedContactAlert,
        contactLimit,
        contactPage,
      ]);
    }
  };
  const handleAlertDialog = () => {
    setIsOpenAlertDialog(!isOpenAlertDialog);
  };

  // UI show
  const { data: alerts } = useAlertList({
    onError: () => {
      enqueueSnackbar("Failed to get alerts", {
        variant: "error",
      });
    },
  });

  const totalAlertHit = useMemo(() => {
    return alerts?.reduce((acc, alert) => {
      return acc + (alert.total || 0);
    }, 0);
  }, [alerts]);

  const totalAlertHitLastMonth = useMemo(() => {
    return alerts?.reduce((acc, alert) => {
      return acc + (alert.totalLastMonth || 0);
    }, 0);
  }, [alerts]);

  const createAlert = useCreateAlert();
  const handleCreateAlert = () => {
    createAlert.mutate(alertData, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("alertList");
        setSelectedContactAlert(data.alertId);
        setIsAlertTotalItemSelected(false);
        handleAlertDialog();
      },
      onError: (error) => {
        enqueueSnackbar(error?.data?.message, {
          variant: "error",
        });
      },
    });
  };

  const updateAlert = useUpdateAlert();
  const handleUpdateAlert = () => {
    updateAlert.mutate(
      {
        id: alertData.id,
        alertData,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("alertList");
          if (selectedContactAlert === data.alertId) {
            setSelectedContactAlert(null);
          }
          setSelectedContactAlert(data.alertId);
          setIsAlertTotalItemSelected(false);
          handleAlertDialog();
        },
        onError: (error) => {
          enqueueSnackbar(error?.data?.message, {
            variant: "error",
          });
        },
      }
    );
  };
  // --------- alert logic section ------------

  const totalContacts = useMemo(() => {
    return selectedContactAlert && isFetchContactByAlertSuccess
      ? totalContactsByAlert
      : totalGeneralContacts;
  }, [
    selectedContactAlert,
    isFetchContactByAlertSuccess,
    totalContactsByAlert,
    totalGeneralContacts,
  ]);

  const buildContactListData = useMemo(() => {
    const contactListData =
      selectedContactAlert && isFetchContactByAlertSuccess
        ? contactListByAlert
        : contactList;
    return contactListData.map((contact) => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        lastLoginTime,
        id,
        maxBuyingPower,
        minBuyingPower,
        cashAvailable,
        debtOutstanding,
        lvr,
        maxLoanAmount,
        currentInterestRate,
        hasMetBuyingGoal,
        hasMetSavingGoal,
        excessMonthlySurplus,
        equity,
        createdAt,
      } = contact;
      const contactName = `${firstName} ${lastName}`.trim();
      const isLastLoginAvailable = !!lastLoginTime;
      return createData({
        id,
        contactName,
        contactDetail: {
          email,
          phoneNumber,
        },
        maxBuyingPower,
        minBuyingPower,
        cashAvailable,
        debtOutstanding,
        lastAppVisit: isLastLoginAvailable
          ? monthDayFormater(new Date(lastLoginTime))
          : monthDayFormater(new Date(createdAt)),
        lvr,
        maxLoanAmount,
        currentInterestRate,
        hasMetBuyingGoal,
        hasMetSavingGoal,
        excessMonthlySurplus,
        equity,
        handleViewAccount: () => handleViewAccount(id),
        handleShareDetail: () => handleShareDetailDialog(id),
        handleCheckAccount: (event) => handleCheckAccount(email, event),
      });
    });
  }, [
    contactList,
    contactListByAlert,
    handleShareDetailDialog,
    handleCheckAccount,
    isFetchContactByAlertSuccess,
    selectedContactAlert,
  ]);
  const currentPageContactIds = useMemo(() => {
    return buildContactListData.map((contact) => contact.id);
  }, [buildContactListData]);

  // --------- contacts map for alert hits ------------
  const contactAlertsMap = useGetSatisfiedContactsByAlertsWithinGivenContacts(
    alerts,
    currentPageContactIds
  );

  const parseCSV = (text) => {
    return text
      .trim()
      .split("\n")
      .slice(1)
      .map((row, index) => {
        const [firstName, lastName, email, phoneNumber, companyId] = row.split(
          ","
        );
        return {
          id: index,
          phoneNumber,
          email,
          firstName,
          lastName,
          companyId,
          isImported: null,
        };
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const contactDTO = parseCSV(text);
      setCreateContactDTOs(contactDTO);
      setIsOpenPreview(true);
    };
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
    reader.readAsText(file);
  };

  const handleOpenDialog = () => {
    setIsRetry(false);
    setIsOpenPreview(!isOpenPreview);
  };

  const importContact = useImportContactlist();

  const handleSubmit = (dtoList) => {
    setIsLoading(true);
    const filteredDTOs = isRetry
      ? dtoList.filter((dto) => !dto.isImported)
      : dtoList;
    setIsRetry(false);

    importContact.mutate(filteredDTOs, {
      onSuccess: (data) => {
        setIsSnackbarOpen(true);
        const insertCount = data.contactList.filter((row) => row.isImported)
          .length;
        setSnackMessage(`${insertCount} contacts inserted`);
        setIsLoading(false);
        if (data.isSuccess) {
          refetch();
          setIsOpenPreview(false);
          return;
        }
        setCreateContactDTOs(
          data.contactList.filter((contact) => !contact.isImported)
        );
        setIsRetry(true);
      },
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackbarOpen(false);
  };

  return (
    <ContactContext.Provider
      value={{
        handleDialog,
        handleShareDetailDialog,
        selectedContactAlert,
        setSelectedContactAlert,
        handleSelectContactAlert,
        isAlertTotalItemSelected,
        setIsAlertTotalItemSelected,
        alertData,
        setAlertData,
        handleAlertDialog,
        handleCreateAlert,
        handleUpdateAlert,
      }}
    >
      <HeaderComponent />
      <div className="contact-page">
        <TotalContact totalContact={totalGeneralContacts ?? 0} />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={snackMessage}
        />
        <ImportContactButton onClick={handleFileChange} />
        <FilePreviewDialog
          contactList={createContactDTOs}
          isOpen={isOpenPreview}
          handleOpenDialog={handleOpenDialog}
          handleSubmit={handleSubmit}
          isRetry={isRetry}
          isLoading={isLoading}
        />
        <ContactAlert
          alertList={alerts}
          totalAlertHit={totalAlertHit}
          totalAlertLastMonth={totalAlertHitLastMonth}
        />
        <div>
          <FormControl style={{ minWidth: "20%" }}>
            <InputLabel>Action</InputLabel>
            <Select
              label="Action"
              value=""
              onChange={handleEmailEditorDialog}
              disabled={!checkedEmails.length}
            >
              {menuItems.map((item, index) => [
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>,
                index === 0 && <Divider />,
                index === 1 && (
                  <>
                    <Divider />
                    <Typography className="email-dialog__auto-follow-up">
                      Send auto follow up til connected
                    </Typography>
                  </>
                ),
              ])}
            </Select>
          </FormControl>
        </div>
        <ContactTable
          tableHeader={columns}
          contactList={buildContactListData}
          refetch={refetch}
          total={totalContacts}
          onChange={onContactTableChange}
          page={contactPage}
          rowsPerPage={contactLimit}
          contactMapByAlerts={contactAlertsMap}
          selectedAlert={selectedContactAlert}
        />
      </div>
      <ContactAlertDialog
        isOpen={isOpenAlertDialog}
        handleOpenDialog={handleAlertDialog}
        isCompanyAdmin={isCompanyAdmin}
      />
      <ShareDetailsDialog
        contactId={selectedContact}
        isOpen={isOpenShareDetailDialog}
        handleOpenDialog={handleShareDetailDialog}
      />
      <EmailEditorDialog
        isOpen={isOpenEmailEditorDialog}
        emailList={checkedEmails}
        period={period}
        setIsOpenEmailEditorDialog={setIsOpenEmailEditorDialog}
      />
    </ContactContext.Provider>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
