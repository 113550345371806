/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
export const transformQbTreeAndGetUserInteractionObjects = (
  node,
  config,
  userInteractions = []
) => {
  if (
    node.properties &&
    node.properties?.field &&
    node.properties.field === "user_interaction" &&
    node.type === "rule_group"
  ) {
    const userInteractionValues = {};
    Object.keys(node.children1 || {}).forEach((key) => {
      const child = node.children1[key];
      const fields = [
        "user_interaction.interaction_type",
        "user_interaction.interaction_times",
        "user_interaction.lastXDays",
      ];
      if (fields.includes(child?.properties?.field)) {
        const fieldName = child.properties.field.split(".")[1];
        if (
          userInteractionValues[fieldName] !== undefined &&
          userInteractionValues[fieldName] !== null
        ) {
          throw new Error(
            "You can only fill once for each of the fields: interaction type, times and lastXDays"
          );
        }
        if (fieldName === "interaction_times") {
          userInteractionValues[fieldName] = {
            operator: child.properties.operator,
            value: child.properties.value[0],
          };
        } else {
          userInteractionValues[fieldName] = child.properties.value[0];
        }
      }
    });
    if (Object.keys(userInteractionValues).length < 3) {
      throw new Error(
        "You must fill all of the fields: interaction type, times and lastXDays"
      );
    }
    const subQueryName = `user_interaction_${node.id.replaceAll("-", "_")}`;
    userInteractionValues["sub_query_name"] = subQueryName;
    userInteractions.push(userInteractionValues);
    delete node["children1"];
    node["type"] = "rule";
    node["properties"] = {
      valueError: [],
      field: `${subQueryName}.user_id`,
      operator: "is_not_null",
      operatorOptions: null,
      value: [],
      valueSrc: [],
      valueType: [],
    };
    config.fields[`${subQueryName}.user_id`] = {
      type: "text",
      operators: ["is_not_null"],
      valueSources: ["value"],
      preferWidgets: ["text"],
    };
  } else if (node.children1) {
    Object.keys(node.children1).forEach((key) => {
      transformQbTreeAndGetUserInteractionObjects(
        node.children1[key],
        config,
        userInteractions
      );
    });
  }
  return { config, userInteractions };
};
