import React from "react";
import PropTypes from "prop-types";

const TotalContact = ({ totalContact = 0 }) => {
  return (
    <div className="contact__total-contact">
      Total Contacts <span>{totalContact}</span>
    </div>
  );
};

TotalContact.propTypes = {
  totalContact: PropTypes.number,
};

export default TotalContact;
