import { FormControl, TextField } from "@mui/material";
import React, { useRef } from "react";
import NumberFormat from "react-number-format";

const MaterialNumberWithDollar = (props) => {
  const {
    value,
    setValue,
    readonly,
    min,
    max,
    step,
    placeholder,
    customProps,
  } = props;

  const ref = useRef("");
  const onChange = (e) => {
    let val = e.target.value;
    if (val === "" || val === null) val = undefined;
    else val = Number(val);
    setValue(val);
  };

  const numberValue = value == undefined ? "" : value;

  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, isMoney, ...other } = props;
    return (
      <NumberFormat
        {...other}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue >= 0;
        }}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.floatValue,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  return (
    <FormControl>
      <TextField
        inputRef={ref}
        className="filter-input-number"
        label=""
        value={value}
        placeholder={!readonly ? placeholder : ""}
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps: {
            isMoney: true,
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          min,
          max,
          step,
        }}
        disabled={readonly}
        onChange={onChange}
        autoFocus
        {...customProps}
      />
    </FormControl>
  );
};

export default MaterialNumberWithDollar;
