import React from "react";
import { BasicConfig } from "react-awesome-query-builder";
import { SqlString } from "../../../utils/sql";
import MaterialWidgets from "../index";

const {
  MaterialBooleanWidget,
  MaterialTextWidget,
  MaterialTextAreaWidget,
  MaterialDateWidget,
  MaterialTimeWidget,
  MaterialDateTimeWidget,
  MaterialMultiSelectWidget,
  MaterialSelectWidget,
  MaterialNumberWidget,
  MaterialNumberWithDollarWidget,
  MaterialSliderWidget,
  MaterialRangeWidget,
  MaterialAutocompleteWidget,
  MaterialFieldSelect,
  MaterialConjs,
  MaterialButton,
  MaterialButtonGroup,
  MaterialValueSources,
  MaterialProvider,
  materialConfirm,
  MaterialUseConfirm,
} = MaterialWidgets;

const settings = {
  ...BasicConfig.settings,
  renderField: (props) => <MaterialFieldSelect {...props} />,
  renderOperator: (props) => <MaterialFieldSelect {...props} />,
  renderFunc: (props) => <MaterialFieldSelect {...props} />,
  renderConjs: (props) => <MaterialConjs {...props} />,
  renderButton: (props) => <MaterialButton {...props} />,
  renderButtonGroup: (props) => <MaterialButtonGroup {...props} />,
  renderValueSources: (props) => <MaterialValueSources {...props} />,
  renderProvider: (props) => <MaterialProvider {...props} />,
  renderConfirm: materialConfirm,
  useConfirm: MaterialUseConfirm,
  showNot: false,
};

const operators = {
  ...BasicConfig.operators,
  equal: {
    ...BasicConfig.operators.equal,
    label: "Is",
  },
  not_equal: {
    ...BasicConfig.operators.not_equal,
    label: "Is not",
  },
  select_equals: {
    ...BasicConfig.operators.equal,
    label: "Is",
  },
  select_not_equals: {
    ...BasicConfig.operators.not_equal,
    label: "Is not",
  },
  greater: {
    ...BasicConfig.operators.greater,
    label: "Greater than",
  },
  greater_or_equal: {
    ...BasicConfig.operators.greater_or_equal,
    label: "Greater or equal",
  },
  less: {
    ...BasicConfig.operators.less,
    label: "Lower",
  },
  less_or_equal: {
    ...BasicConfig.operators.less_or_equal,
    label: "Lower or equal",
  },
};

const widgets = {
  ...BasicConfig.widgets,
  text: {
    ...BasicConfig.widgets.text,
    factory: (props) => <MaterialTextWidget {...props} />,
  },
  textarea: {
    ...BasicConfig.widgets.textarea,
    factory: (props) => <MaterialTextAreaWidget {...props} />,
  },
  number: {
    ...BasicConfig.widgets.number,
    factory: (props) => <MaterialNumberWidget {...props} />,
  },
  numberWithDollar: {
    ...BasicConfig.widgets.number,
    factory: (props) => <MaterialNumberWithDollarWidget {...props} />,
  },
  multiselect: {
    ...BasicConfig.widgets.multiselect,
    factory: (props) => {
      return props.asyncFetch || props.showSearch ? (
        <MaterialAutocompleteWidget multiple {...props} />
      ) : (
        <MaterialMultiSelectWidget {...props} />
      );
    },
  },
  select: {
    ...BasicConfig.widgets.select,
    factory: (props) => {
      return props.asyncFetch || props.showSearch ? (
        <MaterialAutocompleteWidget {...props} />
      ) : (
        <MaterialSelectWidget {...props} />
      );
    },
  },
  slider: {
    ...BasicConfig.widgets.slider,
    factory: (props) => <MaterialSliderWidget {...props} />,
  },
  boolean: {
    ...BasicConfig.widgets.boolean,
    factory: (props) => <MaterialBooleanWidget {...props} />,
  },
  date: {
    ...BasicConfig.widgets.date,
    dateFormat: "yyyy-MM-dd",
    valueFormat: "yyyy-MM-dd",
    factory: (props) => <MaterialDateWidget {...props} />,
  },
  time: {
    ...BasicConfig.widgets.time,
    factory: (props) => <MaterialTimeWidget {...props} />,
  },
  datetime: {
    ...BasicConfig.widgets.datetime,
    factory: (props) => <MaterialDateTimeWidget {...props} />,
  },

  rangeslider: {
    type: "number",
    jsType: "number",
    valueSrc: "value",
    factory: (props) => <MaterialRangeWidget {...props} />,
    valueLabel: "Range",
    valuePlaceholder: "Select range",
    valueLabels: [
      { label: "Number from", placeholder: "Enter number from" },
      { label: "Number to", placeholder: "Enter number to" },
    ],
    formatValue: (val, fieldDef, wgtDef, isForDisplay) => {
      return isForDisplay ? val : JSON.stringify(val);
    },
    sqlFormatValue: (val, fieldDef, wgtDef, op, opDef) => {
      return SqlString.escape(val);
    },
    singleWidget: "slider",
    toJS: (val, fieldSettings) => val,
  },
};

const types = {
  ...BasicConfig.types,
  number: {
    ...BasicConfig.types.number,
    widgets: {
      ...BasicConfig.types.number.widgets,
      rangeslider: {
        opProps: {
          between: {
            isSpecialRange: true,
          },
          not_between: {
            isSpecialRange: true,
          },
        },
        operators: ["between", "not_between", "is_empty", "is_not_empty"],
      },
    },
  },
  numberWithDollar: {
    ...BasicConfig.types.number,
    mainWidget: "numberWithDollar",
    widgets: {
      ...BasicConfig.types.number.widgets,
      numberWithDollar: {
        operators: [
          "equal",
          "less",
          "less_or_equal",
          "greater",
          "greater_or_equal",
        ],
      },
    },
  },
};

export default {
  ...BasicConfig,
  operators,
  types,
  widgets,
  settings,
};
