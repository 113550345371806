import { IContactAlert } from "./contact-alert-item";
import ContactAlertListCarousel from "./contact-alert-list";
interface ContactAlertProps {
  alertList: IContactAlert[];
  totalAlertHit: number;
  totalAlertLastMonth: number;
}
const ContactAlert: React.FC<ContactAlertProps> = ({
  alertList,
  totalAlertHit,
  totalAlertLastMonth,
}) => {
  return (
    <div className="contact-alert">
      <div className="contact-alert__title">Alerts</div>
      <ContactAlertListCarousel
        totalAlertHit={totalAlertHit}
        totalAlertLastMonth={totalAlertLastMonth}
        alertList={alertList}
      />
    </div>
  );
};

export default ContactAlert;
