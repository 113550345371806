import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import omit from "lodash/omit";
import React from "react";
import { mapListValues } from "../../../utils/stuff";

const MaterialMultiSelect = ({
  listValues,
  value,
  setValue,
  readonly,
  placeholder,
  customProps,
}) => {
  const renderOptions = (selectedValues) =>
    mapListValues(listValues, ({ title, listValue }) => {
      return (
        <MenuItem key={listValue} value={listValue}>
          <Checkbox checked={selectedValues.indexOf(listValue) > -1} />
          <ListItemText primary={title} />
        </MenuItem>
      );
    });

  const renderValue = (selectedValues) => {
    if (!readonly && !selectedValues.length) return placeholder;
    const selectedTitles = mapListValues(listValues, ({ title, listValue }) =>
      selectedValues.indexOf(listValue) > -1 ? title : null
    ).filter((v) => v !== null);
    return selectedTitles.join(", ");
  };

  const hasValue = value != null && value.length > 0;

  const onChange = (e) => {
    if (e.target.value === undefined) return;
    setValue(e.target.value);
  };

  return (
    <FormControl>
      <Select
        multiple
        autoWidth
        displayEmpty
        onChange={onChange}
        value={hasValue ? value : []}
        disabled={readonly}
        readOnly={readonly}
        renderValue={renderValue}
        {...omit(customProps, ["showSearch", "input", "showCheckboxes"])}
      >
        {renderOptions(hasValue ? value : [])}
      </Select>
    </FormControl>
  );
};

export default MaterialMultiSelect;
