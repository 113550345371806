import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const typeToOnlyIcon = {
  delGroup: <DeleteIcon />,
  delRuleGroup: <DeleteIcon />,
  delRule: <DeleteIcon />,
  addRuleGroup: <AddIcon />,
  addRuleGroupExt: <AddIcon />,
};
const typeToIcon = {
  addRule: <AddCircleOutlineIcon />,
  addGroup: <AddCircleOutlineIcon />,
};
const typeToColor = {
  addRule: "secondary",
  addGroup: "secondary",
  delGroup: "error",
  delRuleGroup: "error",
  delRule: "error",
};

const MaterialButton = ({ type, label, onClick }) => {
  if (typeToOnlyIcon[type])
    return (
      <IconButton size="small" onClick={onClick} color={typeToColor[type]}>
        {typeToOnlyIcon[type]}
      </IconButton>
    );
  return (
    <Button
      variant="text"
      size="small"
      onClick={onClick}
      color={typeToColor[type]}
      startIcon={typeToIcon[type]}
    >
      {label}
    </Button>
  );
};

export default MaterialButton;
