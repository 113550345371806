import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Builder,
  Query,
  Utils as QbUtils,
  JsonGroup,
  JsonTree,
  Config,
  ImmutableTree,
  JsonAnyRule,
} from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/styles.css";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../components/custom-button";
import MaterialConfig from "../../components/material-v5-widget/config";
import ContactContext from "../../context/contact-context";
import "../../scss/query-builder.scss";
import { TContactContext } from "../../context/contact-context.type";
import { TContactAlertSeverity } from "./contact-alert-severity-indicator";
import { transformToCamelCase } from "../../utils/stuff";
import { transformQbTreeAndGetUserInteractionObjects } from "../../utils/tree";
import { useSnackbar } from "notistack";
import { cloneDeep } from "lodash";
import { AlertSharingType } from "./contact-alert-item";
const InitialConfig = MaterialConfig;
const baseConfig: Config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    showErrorMessage: true,
  },
  fields: {
    "userMetric.max_real_buying_power": {
      label: "Max Real Buying Power",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userMetric.min_real_buying_power": {
      label: "Min Real Buying Power",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userMetric.max_loan_amount": {
      label: "Max Loan Amount",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userMetric.debt_outstanding": {
      label: "Outstanding Debt",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userMetric.lvr": {
      label: "Current LVR",
      type: "number",
      fieldSettings: {
        min: 0,
        max: 100,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      defaultValue: 0,
      preferWidgets: ["number"],
    },
    "userMetric.current_interest_rate": {
      label: "Current Interest Rate",
      type: "number",
      fieldSettings: {
        min: 0,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      defaultValue: 0,
      preferWidgets: ["number"],
    },
    "userMetric.has_met_buying_goal": {
      label: "Has Met Buying Goal",
      type: "boolean",
      defaultValue: true,
      mainWidgetProps: {
        labelYes: "+",
        labelNo: "-",
      },
    },
    "userMetric.excess_monthly_surplus": {
      label: "Excess Monthly Surplus",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
    "userMetric.equity": {
      label: "Equity Amount",
      type: "numberWithDollar",
      fieldSettings: {
        min: 0,
      },
      operators: [
        "equal",
        "less",
        "less_or_equal",
        "greater",
        "greater_or_equal",
      ],
      valueSources: ["value"],
      preferWidgets: ["numberWithDollar"],
    },
  },
};

const config: Config = {
  ...baseConfig,
  fields: {
    ...baseConfig.fields,
    user_interaction: {
      label: "User interaction in last X days",
      type: "!group",
      subfields: {
        lastXDays: {
          label: "Last X days",
          type: "number",
          fieldSettings: {
            min: 1,
          },
          operators: ["equal"],
          valueSources: ["value"],
          defaultValue: 1,
        },
        interaction_type: {
          label: "Kind Of Interaction",
          type: "select",
          operators: ["select_equals"],
          fieldSettings: {
            listValues: [
              {
                value: "VISIT_BUYING_POWER",
                title: "Visit buying power",
              },
            ],
          },
          defaultValue: "VISIT_BUYING_POWER",
        },
        interaction_times: {
          label: "Interaction times",
          type: "number",
          fieldSettings: {
            min: 0,
          },
          operators: [
            "equal",
            "less",
            "less_or_equal",
            "greater",
            "greater_or_equal",
          ],
          valueSources: ["value"],
          defaultValue: 0,
        },
      },
    },
  },
};

export const queryValue: JsonTree = {
  id: uuidv4(),
  type: "group",
  children1: {
    [uuidv4()]: {
      type: "rule",
      properties: {
        field: null,
        operator: null,
        value: [],
        valueSrc: [],
      },
    },
  },
};

const severities: Array<{
  value: number;
  code: TContactAlertSeverity;
  text: string;
}> = [
  { value: 1, code: "WATCH", text: "Watch" },
  { value: 2, code: "NEED_ACTION", text: "Need Action" },
];

const ContactAlertDialog = ({
  isOpen = false,
  handleOpenDialog = () => {},
  isCompanyAdmin = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleCreateAlert,
    alertData,
    setAlertData,
    handleUpdateAlert,
  } = useContext<TContactContext>(ContactContext);
  const [state, setState] = useState<{ tree?: ImmutableTree; config?: Config }>(
    {}
  );
  const [alertNameInput, setAlertNameInput] = useState("");
  const [shareAcrossCompany, setShareAcrossCompany] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = useState(
    () => severities[0].value
  );
  const callingFunctionRef = useRef(false);
  const isUpdateAlert = useMemo(() => {
    return !!alertData?.id;
  }, [alertData]);
  useEffect(() => {
    if (alertData) {
      setAlertNameInput(alertData.name);
      setState({
        tree: QbUtils.checkTree(
          QbUtils.loadTree(alertData.filterSegment as JsonGroup),
          config
        ),
        config,
      });
      setSelectedSeverity(alertData.severity);
      setShareAcrossCompany(alertData.sharingType === AlertSharingType.COMPANY);
    } else {
      setAlertNameInput("");
      setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
        config,
      });
      setSelectedSeverity(severities[0].value);
      setShareAcrossCompany(false);
    }
  }, [isOpen]);

  const onChange = (immutableTree: ImmutableTree, newConfig: Config) => {
    setState({ tree: immutableTree, config: newConfig });
  };
  const handleInputFilterName = (e) => {
    setAlertNameInput(e.target.value);
  };

  const handleSendDataAndCreateFilter = () => {
    try {
      const clonedTreeState = JSON.parse(JSON.stringify(state.tree));
      const {
        config: newConfig,
        userInteractions,
      } = transformQbTreeAndGetUserInteractionObjects(
        clonedTreeState,
        cloneDeep(baseConfig)
      );
      QbUtils.checkTree(QbUtils.loadTree(clonedTreeState), newConfig);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      return;
    }
    setAlertData((prevState) => ({
      ...prevState,
      name: alertNameInput || "New Alert",
      filterSegment: JSON.parse(JSON.stringify(state.tree)),
      severity: selectedSeverity,
      sharingType: shareAcrossCompany
        ? AlertSharingType.COMPANY
        : AlertSharingType.PRIVATE,
    }));
    callingFunctionRef.current = true;
  };

  useEffect(() => {
    if (callingFunctionRef.current) {
      callingFunctionRef.current = false;
      if (isUpdateAlert) {
        handleUpdateAlert();
      } else {
        handleCreateAlert();
      }
    }
  }, [alertData]);

  const renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  return (
    <Dialog
      onClose={handleOpenDialog}
      aria-labelledby="customized-dialog-alert-title"
      className="contact-filter-dialog"
      open={isOpen}
    >
      <div
        className="contact-filter-dialog__title"
        id="customized-dialog-title"
      >
        <div className="name">Create New Alert</div>
        <div className="severity">
          <div className="label">Select alert trigger</div>
          {severities.map((severity) => (
            <Button
              onClick={() => {
                setSelectedSeverity(severity.value);
              }}
              key={severity.value}
              id={`severity__btn_${severity.code}`}
              className="severity__btn"
              style={{
                borderColor: `var(--${transformToCamelCase(
                  severity.code
                )}AlertColor)`,
                backgroundColor:
                  severity.value === selectedSeverity
                    ? `var(--${transformToCamelCase(
                        severity.code
                      )}AlertColorTint)`
                    : "transparent",
              }}
            >
              {severity.text}
            </Button>
          ))}
        </div>
      </div>
      <div>
        <TextField
          id="alert-name"
          label="Alert name"
          variant="outlined"
          value={alertNameInput}
          onChange={handleInputFilterName}
        />
      </div>

      {isCompanyAdmin ? (
        <div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className="common-theme__checkbox"
                  onChange={(e) => {
                    setShareAcrossCompany(e.target.checked);
                  }}
                  checked={shareAcrossCompany}
                />
              }
              label="Share accross company"
            />
          </FormGroup>
        </div>
      ) : null}

      <div className="contact-filter-dialog__query-builder">
        <Query
          {...config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
      </div>
      <DialogActions>
        <CustomButton
          autoFocus
          onClick={handleSendDataAndCreateFilter}
          label={isUpdateAlert ? "Update Alert" : "Create Alert"}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ContactAlertDialog;
