import Plus from "../../svgs/Plus";
interface AddContactAlertButtonProps {
  onClick?: () => void;
}
const AddContactAlertButton: React.FC<AddContactAlertButtonProps> = ({
  onClick = () => {},
}) => {
  return (
    <div className="carousel-item-container" onClick={onClick}>
      <div className="carousel-item-container__add-alert-btn">
        <div>Create Alert</div>
        <div className="plus-icon">
          <Plus />
        </div>
      </div>
    </div>
  );
};

export default AddContactAlertButton;
