import { FormControl, TextField } from "@mui/material";
import React, { useRef } from "react";

const MaterialNumber = (props) => {
  const {
    value,
    setValue,
    readonly,
    min,
    max,
    step,
    placeholder,
    customProps,
  } = props;

  const ref = useRef("");
  const onChange = (e) => {
    let val = e.target.value;
    if (val === "" || val === null) val = undefined;
    else val = Number(val);
    setValue(val);
  };

  const numberValue = value === undefined ? "" : value;

  return (
    <FormControl>
      <TextField
        inputRef={ref}
        className="filter-input-number"
        label=""
        value={numberValue}
        placeholder={!readonly ? placeholder : ""}
        InputProps={{
          readOnly: readonly,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          min,
          max,
          step,
        }}
        disabled={readonly}
        onChange={onChange}
        autoFocus
        {...customProps}
      />
    </FormControl>
  );
};

export default MaterialNumber;
