import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import EmailEditor from "react-email-editor";
import { CustomOutlineButton } from "../../components/custom-button";
import {
  useCreateEmail,
  useSaveTemplate,
  useTemplateList,
} from "../../hooks/email.hooks";
import { USER_ATTRIBUTES, TEST_DESIGN_TAGS } from "./merge-tags";

const TemplateDropdown = ({ templateList, onSelectTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(
    templateList?.[0] ?? null
  );

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
    onSelectTemplate(template.id, template.designContent);
  };

  return (
    <div className="template-dropdown-container">
      <select
        className="template-select"
        onChange={(e) => {
          const template = templateList.find((t) => t.id === e.target.value);
          handleTemplateChange(template);
        }}
      >
        <option value="" disabled>
          Select a template
        </option>
        {templateList?.map((template) => (
          <option key={template.id} value={template.id}>
            {template.templateName || `Template ${template.id}`}
          </option>
        ))}
      </select>

      {selectedTemplate && (
        <div className="template-preview">
          <h4>Preview</h4>
          <div className="preview-frame">
            <iframe
              srcDoc={selectedTemplate.htmlContent}
              title="Template Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const EmailEditorDialog = ({
  isOpen = false,
  emailList = [],
  period,
  setIsOpenEmailEditorDialog,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showEditor, setShowEditor] = useState(false);
  const emailEditorRef = useRef(null);
  const saveTemplate = useSaveTemplate();
  const createEmail = useCreateEmail();
  const { data: templateList, refetch } = useTemplateList();
  const [activeTemplateId, setActiveTemplateId] = useState();
  const [activeTemplate, setActiveTemplate] = useState();
  const [templateName, setTemplateName] = useState("");
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [isEmailEditorLoading, setIsEmailEditorLoading] = useState(true);

  useEffect(() => {
    if (templateList?.length) {
      setActiveTemplateId(templateList[0].id);
      setActiveTemplate(templateList[0].designContent);
    }
  }, [templateList]);

  const handleSelectTemplate = (id, template) => {
    setActiveTemplateId(id);
    setActiveTemplate(template);
    if (template && emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(template);
    }
  };

  const handleEditTemplate = () => {
    setIsEmailEditorLoading(true);
    setShowEditor(true);
  };

  const handleEditorReady = () => {
    setIsEmailEditorLoading(false);
    if (activeTemplate && emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(activeTemplate);
    }
  };

  const handleSend = () => {
    createEmail.mutate(
      { emailList, templateId: activeTemplateId, period },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar(`Email sent`, {
            variant: "success",
            autoHideDuration: 2000,
          });
          setShowEditor(false);
          setIsNameDialogOpen(false);
        },
        onError: (err) => {
          enqueueSnackbar(err.message, {
            variant: "Error creating email template",
          });
        },
      }
    );
    setIsOpenEmailEditorDialog(false);
  };

  const handleSave = (templateId) => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        saveTemplate.mutate(
          {
            templateName,
            htmlContent: html,
            designContent: design,
            templateId: templateId ?? null,
          },
          {
            onSuccess: () => {
              refetch();
              enqueueSnackbar(
                `Template ${templateId ? "updated" : "created"}`,
                { variant: "success", autoHideDuration: 2000 }
              );
              setShowEditor(false);
              setIsNameDialogOpen(false);
            },
            onError: (err) => {
              enqueueSnackbar(err.message, {
                variant: "Error creating email template",
              });
            },
          }
        );
      });
    }
  };

  const handleBack = () => {
    setShowEditor(false);
  };

  const handleCloseDialog = () => {
    setIsOpenEmailEditorDialog(false);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleCloseDialog}>
        <div className="email-dialog">
          {showEditor ? (
            <div id="email-container" className="editor-container">
              <div className="button-group">
                <CustomOutlineButton label="Back" onClick={handleBack} />
                <CustomOutlineButton
                  label="Update Email"
                  onClick={() => handleSave(activeTemplateId)}
                />
                <CustomOutlineButton
                  label="Save as New Email"
                  onClick={() => setIsNameDialogOpen(true)}
                />
              </div>
              <React.StrictMode>
                <div className="loading-container">
                  {isEmailEditorLoading && <CircularProgress />}
                </div>
                <EmailEditor
                  ref={emailEditorRef}
                  onReady={handleEditorReady}
                  options={{
                    mergeTags: USER_ATTRIBUTES,
                    designTags: TEST_DESIGN_TAGS,
                  }}
                />
              </React.StrictMode>
            </div>
          ) : (
            <>
              <div className="button-group">
                <CustomOutlineButton
                  label="Edit Email"
                  onClick={handleEditTemplate}
                  disabled={!activeTemplate}
                />
                <CustomOutlineButton
                  label="Send"
                  onClick={handleSend}
                  disabled={!activeTemplate}
                />
              </div>
              <TemplateDropdown
                templateList={templateList}
                onSelectTemplate={handleSelectTemplate}
              />
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        open={isNameDialogOpen}
        onClose={() => setIsNameDialogOpen(false)}
      >
        <DialogTitle>Enter Template Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Template Name"
            fullWidth
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => setIsNameDialogOpen(false)}>
            Cancel
          </Button>
          <CustomOutlineButton
            label="Save"
            onClick={() => handleSave(null)}
            buttonType="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailEditorDialog;
