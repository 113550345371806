import { useMutation, useQuery } from "react-query";
import {
  createEmail,
  saveTemplate,
  getTemplateList,
} from "../api-services/email";

export const useCreateEmail = () => {
  return useMutation(createEmail);
};

export const useSaveTemplate = () => {
  return useMutation(saveTemplate);
};

export const useTemplateList = () => {
  return useQuery("templateList", getTemplateList);
};
