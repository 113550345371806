import { FormControl, ListSubheader, MenuItem, Select } from "@mui/material";
import React from "react";

const renderOptions = (fields, level = 0) =>
  Object.keys(fields).map((fieldKey) => {
    const field = fields[fieldKey];
    const { fieldItems, path, label, disabled } = field;
    const prefix = "\u00A0\u00A0".repeat(level);
    if (fieldItems) {
      return [
        <ListSubheader disabled={disabled} key={path} disableSticky>
          {label}
        </ListSubheader>,
        renderOptions(fieldItems, level + 1),
      ];
    }
    return (
      <MenuItem disabled={disabled} key={path} value={path}>
        {prefix && <span>{prefix}</span>}
        {label}
      </MenuItem>
    );
  });

const MaterialFieldSelect = ({
  items,
  setField,
  selectedKey,
  readonly,
  placeholder,
}) => {
  const onChange = (e) => {
    if (e.target.value === undefined) return;
    setField(e.target.value);
  };

  const renderValue = (selectedValue) => {
    if (!readonly && !selectedValue) return placeholder;
    const findLabel = (fields) => {
      return fields.map((field) => {
        if (!field.items)
          return field.path === selectedValue ? field.label : null;
        return findLabel(field.items);
      });
    };
    return findLabel(items)
      .filter((v) => {
        if (Array.isArray(v)) {
          return v.some((value) => value !== null);
        }
        return v !== null;
      })
      .pop();
  };

  const hasValue = selectedKey != null;
  return (
    <FormControl>
      <Select
        autoWidth
        displayEmpty
        label=""
        onChange={onChange}
        value={hasValue ? selectedKey : ""}
        disabled={readonly}
        renderValue={renderValue}
      >
        {renderOptions(items)}
      </Select>
    </FormControl>
  );
};

export default MaterialFieldSelect;
