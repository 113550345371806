/* eslint-disable import/order */
import React, { useState, useEffect, useCallback } from "react";
import { Dialog, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import "react-awesome-query-builder/lib/css/styles.css";
import "../../scss/query-builder.scss";

const FilePreviewDialog = ({
  contactList,
  isOpen = false,
  isRetry = false,
  handleOpenDialog = () => {},
  handleSubmit,
  isLoading,
}) => {
  const [editableContent, setEditableContent] = useState(contactList);

  useEffect(() => {
    setEditableContent(contactList);
  }, [contactList]);

  const paginationModel = { page: 0, pageSize: 10 };

  const handleProcessRowUpdate = useCallback(
    (newRow) => {
      const updatedRows = editableContent.map((row) =>
        row.id === newRow.id ? newRow : row
      );
      setEditableContent(updatedRows);
    },
    [editableContent]
  );

  const handleDeleteClick = (id) => () => {
    setEditableContent(editableContent.filter((row) => row.id !== id));
  };

  const columns = [
    { field: "id", headerName: "No.", width: 10 },
    {
      field: "firstName",
      headerName: "First name",
      width: 130,
      editable: true,
    },
    { field: "lastName", headerName: "Last name", width: 130, editable: true },
    { field: "email", headerName: "Email", width: 230, editable: true },
    { field: "phoneNumber", headerName: "Phone", width: 130, editable: true },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 10,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          // eslint-disable-next-line react/jsx-key
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: "errorMessage",
      headerName: "",
      width: 10,
      renderCell: (params) => (
        <Tooltip title={params.value || ""} arrow>
          <span>{params.value ? <ErrorIcon sx={{ color: "red" }} /> : ""}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Dialog
      onClose={handleOpenDialog}
      aria-labelledby="customized-dialog-title"
      className="file-preview-dialog"
      open={isOpen}
    >
      <div className="file-preview-dialog__preview-container">
        <Paper className="paper">
          <DataGrid
            rows={editableContent}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10]}
            sx={{ border: 0 }}
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={() => {}}
            editMode="row"
          />
        </Paper>
        <div className="submit-btn">
          <LoadingButton
            size="large"
            onClick={() => handleSubmit(editableContent)}
            title="save"
            variant="contained"
            loading={isLoading}
          >
            {isRetry ? "Retry" : "Submit"}
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
};

FilePreviewDialog.propTypes = {};

export default FilePreviewDialog;
