import { TimePicker } from "@mui/lab";
import { FormControl } from "@mui/material";
import React from "react";

const MaterialTime = (props) => {
  const {
    value,
    setValue,
    use12Hours,
    readonly,
    placeholder,
    timeFormat,
    valueFormat,
    customProps,
    useKeyboard,
  } = props;

  const formatSingleValue = (value) => {
    return value && value.isValid() ? value.format(valueFormat) : undefined;
  };

  const handleChange = (value) => {
    setValue(formatSingleValue(value));
  };

  const hasSeconds = timeFormat.indexOf(":ss") != -1;
  const timeValue = value || null;

  return (
    <FormControl>
      <TimePicker
        readOnly={readonly}
        disabled={readonly}
        ampm={!!use12Hours}
        placeholder={!readonly ? placeholder : ""}
        format={timeFormat}
        value={timeValue || null}
        onChange={handleChange}
        views={
          hasSeconds ? ["hours", "minutes", "seconds"] : ["hours", "minutes"]
        }
        {...customProps}
      />
    </FormControl>
  );
};

export default MaterialTime;
