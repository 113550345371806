import { FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, isValid } from "date-fns";
import React from "react";

const formatSingleValue = (value, valueFormat) => {
  return value && isValid(value) ? format(value, valueFormat) : undefined;
};

const MaterialDate = (props) => {
  const { value, setValue, valueFormat } = props;

  const handleChange = (dateValue) => {
    setValue(formatSingleValue(dateValue, valueFormat));
  };

  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default MaterialDate;
