import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

const MaterialValueSources = ({
  valueSources,
  valueSrc,
  title,
  setValueSrc,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleOpenClose = (event) => {
    anchorEl ? handleClose() : handleOpen(event);
  };

  const handleChange = (e) => {
    if (e.target.value === undefined) return;
    setValueSrc(e.target.value);
    handleClose();
  };

  const renderOptions = (renderValueSources) =>
    renderValueSources.map(([srcKey, info]) => (
      <FormControlLabel
        key={srcKey}
        value={srcKey}
        checked={valueSrc == srcKey || (!valueSrc && srcKey == "value")}
        control={<Radio />}
        label={info.label}
      />
    ));

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton size="small" onClick={toggleOpenClose}>
        <ExpandMoreIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        disablePortal
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">{title}</FormLabel>
          <RadioGroup value={valueSrc || "value"} onChange={handleChange}>
            {renderOptions(valueSources)}
          </RadioGroup>
        </FormControl>
      </Popover>
    </div>
  );
};

export default MaterialValueSources;
