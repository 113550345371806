import { FormControl, MenuItem, Select } from "@mui/material";
import omit from "lodash/omit";
import React from "react";
import { mapListValues } from "../../../utils/stuff";

const MaterialSelect = ({
  listValues,
  value,
  setValue,
  allowCustomValues,
  readonly,
  placeholder,
  customProps,
}) => {
  const renderOptions = () =>
    mapListValues(listValues, ({ title, value }) => {
      return (
        <MenuItem key={value} value={value}>
          {title}
        </MenuItem>
      );
    });

  const onChange = (e) => {
    if (e.target.value === undefined) return;
    setValue(e.target.value);
  };

  const getListValueTitle = (selectedValue) =>
    mapListValues(listValues, ({ title, value }) =>
      value === selectedValue ? title : null
    )
      .filter((v) => v !== null)
      .shift();

  const renderValue = (selectedValue) => {
    // if (!readonly && selectedValue == null) return placeholder;
    return getListValueTitle(selectedValue);
  };

  const hasValue = value != null;

  return (
    <FormControl>
      <Select
        autoWidth
        displayEmpty
        onChange={onChange}
        value={hasValue ? value : ""}
        disabled={readonly}
        readOnly={readonly}
        renderValue={renderValue}
        // {...omit(customProps, ["showSearch", "input"])}
      >
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

export default MaterialSelect;
