export const USER_ATTRIBUTES = {
  first_name: {
    name: "First Name",
    value: "<%= first_name %>",
    sample: "John",
  },
  last_name: {
    name: "Last Name",
    value: "<%= last_name %>",
    sample: "Doe",
  },
  role: {
    name: "Role",
    value: "<%= role %>",
    sample: "Doe",
  },
  business_company_name: {
    name: "Business Company Name",
    value: "<%= business_company_name %>",
    sample: "Wealthx",
  },
};

export const TEST_DESIGN_TAGS = {
  business_name: "Tesla Inc",
  current_user_name: "Elon Musk",
  business_logo_url: "https://example.com/logo.png",
  unsubscribe_link: "https://example.com/unsubscribe",
};
